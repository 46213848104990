.certificate-item {
    width: 110px;
    height: 160px;
    background-color: #1ba94c;
    margin-left: 5px;
    margin-top: 5px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .medel{
        color: white;
        font-size: 30px;
        margin-left: 10px;
        margin-top: 12px;
    }
    p{
        margin-top: 10px;
        color: white;
        font-weight: 500;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 14px!important;
    }
    &::after {
      content: "";
      height: 40px;
      width: 40px;
      top: 0;
      right: 0;
      background-color: #32c765;
      position: absolute;
    }
    &::before {
      content: "";
      height: 30px;
      width: 83px;
      top: -14px;
      right: -24px;
      background-color: #ffffff;
      position: absolute;
      transform: rotate(45deg);
      z-index: 100;
    }
  }