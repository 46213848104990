.All-teams{
    .approved-button{
        background-color: red;
        height: 20px;
        width: 20px;
        border-radius: 20px;
        &.approved{
            background-color: lime;
        }
    }
}