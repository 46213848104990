.create-certificate-modal {
  .MuiPaper-root {
    max-height: 80vh;
    width: 100%;
    .p {
      margin-top: 20px;
    }
    .tow-sided {
      width: 100%;
      justify-content: space-between;
      display: flex;
      .sx {
        width: 45%;
        input {
          width: 100%;
        }
      }
    }
    .preview {
      width: 100%;
      margin-top: 20px;
      position: relative;
      h1 {
        position: absolute;
        font-size: 10px;
        color: white;
        transform: translate(-50%, -100%);
      }
      img {
        width: 100%;
      }
    }
  }
  .issue {
    textarea {
      width: 100%;
      height: 200px;
    }
  }
  .search-certificate {
    .certificate-item-xx {
      height: 100px;
      width: 100%;
      display: flex;
      margin-top: 10px;
      cursor: pointer;
      img {
        height: 100%;
      }
      .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .details-xy {
        margin-top: 10px;
      }
      p {
        display: flex;
        align-items: center;
        font-size: 16px;
      }
      .abox {
        height: 10px;
        width: 10px;
        border-radius: 10px;
        margin-left: 10px;
      }
    }
  }
}
