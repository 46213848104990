@font-face {
  font-family: "certificate";
  src: local("certificate"),
    url(./fonts/CertificateItalic.ttf) format("truetype");
}
@font-face {
  font-family: "pantherdam";
  src: local("pantherdam"),
    url(./fonts/pantherdam.ttf) format("truetype");
}
@font-face {
  font-family: "kalam-bold-normal";
  src: local("kalam-bold-normal"),
    url(./fonts/Kalam-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "niconne";
  src: local("niconne"), url("./fonts/Niconne-Regular.ttf");
}
* {
  margin: 0;
  padding: 0;
  font-family: "Source Code Pro";
  font-style: normal;
}
button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}
input {
  outline: none;
}

.half-bold {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  color: #202020;
}

.success-color {
  background-color: #28a745 !important;
}
.success-font-color {
  color: #28a745;
}
#root {
  max-width: 1400px;
  margin: auto;
  padding-top: 60px;
  position: relative;
}
@media screen and (max-width: 1440px) {
  #root {
    width: 100%;
  }
}

@media screen and (max-width: 1040px) {
  #root {
    padding-top: 25px;
  }
}

.comming-soon {
  min-height: 60vh;
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 50px;
  justify-content: center;
}

.finished-tag {
  background-color: rgba(0, 150, 0, 0.737);
  color: white;
  position: absolute;
  right: -5px;
  bottom: -5px;
  border-radius: 10px;
  padding: 5px 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
}

em{
  font-style: italic;
}