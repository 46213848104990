.user-pannel {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  position: fixed;
  max-width: 1440px;
  .Tabs {
    width: calc(65% - 70px);
    margin-left: 35%;
    min-height: 100vh;
    margin-left: auto;
    padding: 30px;
  }
  .title {
    font-weight: 800;
    font-size: 30px;
    line-height: 50px;
    text-transform: capitalize;

    color: #222222;
  }
  .input-1 {
    width: 100%;
    height: 35px;
    border: 1px solid #000000;
    border-radius: 5px;
    padding-left: 10px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .post-button {
    background: #202020;
    border: 3px solid #202020;
    margin-bottom: 100px;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-transform: capitalize;
    color: #ffffff;
    width: 150px;
    height: 40px;
    left: 1384px;
    top: 1389px;
    margin-top: 20px;
    float: right;
  }
  .menues {
    width: 35%;
    min-height: calc(100vh - 60px);
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    padding-top: 40px;
    a {
      text-decoration: none !important;
    }
    .single-item {
      padding-left: 10%;
      margin-top: 10px;
      font-weight: 800;
      font-size: 20px;
      display: flex;
      color: white;
      align-items: center;
      height: 60px;
      svg {
        height: 24px;
        width: 24px;
        path {
          fill: rgb(242, 242, 242);
        }
      }
      .item-text {
        margin-left: 10px;
      }
      &.active {
        color: #222222;
        background-color: white;
        svg {
          path {
            fill: rgb(0, 0, 0);
          }
        }
      }
    }
  }
  .my-profile {
    max-width: 100%;
    overflow: hidden;
    .profile-head {
      margin-bottom: 10px;
      margin-top: 20px;
      height: 130px;
      display: flex;
      align-items: center;
      .profile-picture-xyz {
        height: 100%;
        border-radius: 100px;
        margin-right: 20px;
        width: 130px;
        background-color: red;
        overflow: hidden;
        cursor: pointer;
        position: relative;

        img {
          max-width: 100%;
          max-height: 100%;
          height: 100%;
          width: 100%;
        }
        .change-axx {
          background-color: rgba(0, 0, 0, 0.708);
          position: absolute;
          transform: translateY(0%);
          left: 0;
          height: 100%;
          width: 100%;
          transition: 0.2s all;
          display: flex;
          justify-content: center;
          color: white;
          font-size: 14px;
          font-weight: 100;
          padding-top: 20px;
          
        }
        transition: 0.2s all;
        &:hover {
          .change-axx {
            transform: translateY(-50%);
            transition: 0.2s all;
          }
        }
      }
      .upload-xy {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        button {
          width: 150px;
          height: 35px;
          background-color: black;
          color: white;
          font-weight: bold;
          font-size: 15px;
        }
        button:nth-child(2) {
          background-color: #d83333;
        }
      }
    }
  }
  .semi-title {
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
    color: #202020;
    margin-bottom: 10px;
  }
  .duel-item {
    display: flex;
    justify-content: space-between;
    .duel-single-item {
      display: flex;
      flex-direction: column;
      width: 45%;
    }
  }
  .w-75 {
    width: 75%;
  }
  .membership-status {
    .box {
      margin-top: 30px;
      width: 75%;
      height: 150px;
      border: 1px solid #202020;
      border-radius: 6px;
      display: flex;
      align-items: center;
      position: relative;
      .remining-days {
        position: absolute;
        top: 50%;
        left: 50px;
        transform: translate(0, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
          font-size: 20px;
        }
        h2 {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .circuler-box {
        height: 100px !important;
        width: 100px !important;
        margin-left: 20px;
      }
      .CircularProgressbar .CircularProgressbar-trail {
        stroke: #d83333;
      }
      .CircularProgressbar .CircularProgressbar-path {
        stroke: black;
      }
      .details-box {
        margin-left: 40px;
        height: 100px;
      }
    }
  }
  .links {
    .title {
      margin-bottom: 50px;
    }
    .save {
      font-size: 15px;
      margin-right: 15%;
    }
    .two-sided {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 85%;
      height: 80px;
      border: 1px solid #000000;
      border-radius: 6px;
      margin-bottom: 20px;
      .left-side {
        display: flex;
        align-items: center;
        .name-text {
          margin-left: 10px;
          font-weight: 500;
          font-size: 20px;
          text-transform: capitalize;
          color: #202020;
        }
        svg {
          height: 50px;
          margin-left: 10px;
        }
      }
      .input-xy {
        width: 200px;
        margin: 0;
        margin-right: 20px;
      }
      .right-side {
        .post-button {
          float: unset !important;
          width: 100px;
          font-size: 18px;
          margin: 0;
          margin-right: 20px;
        }

        .link-details {
          margin-right: 20px;
          display: flex;
          align-items: center;
          a {
            text-decoration: none;
            color: #202020;
          }
          button {
            margin-right: 20px;
            height: auto;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .change-password {
    display: flex;
    flex-direction: column;
    .pass-change {
      font-size: 15px;
    }
  }
  .options {
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid black;
    width: 240px;
    button {
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      font-weight: 700;
      font-size: 16px;
      text-transform: capitalize;
      cursor: pointer;
      color: #202020;

      svg {
        height: 18px;
        width: 18px;
        path {
          fill: #202020;
        }
        margin-right: 10px;
      }
    }
    .active-option {
      color: white;
      background-color: black;
      svg path {
        fill: white;
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .user-pannel {
    .menues {
      .single-item {
        svg {
          height: 16px;
        }
        .item-text {
          font-size: 16px;
          margin-left: 5px;
        }
      }
    }

    .Tabs {
      padding: 20px;
      width: calc(65% - 20px);
      padding-top: 40px;
      margin-top: 30px;
    }

    .links {
      .two-sided {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100px;
        .left-side {
          svg {
            height: 40px;
            margin-left: 0;
          }
          .name-text {
            font-size: 16px;
            margin-left: 0px;
          }
        }
        .right-side {
          .input-1 {
            border-radius: 5px;
            margin-left: 10px;
            margin-top: 10px;
            font-size: 16px;
            height: 35px;
            &::placeholder {
              font-size: 10px;
            }
          }
          .link-details {
            margin-left: 10px;
            margin-top: 10px;
          }
          .post-button {
            border-radius: 5px;
            margin-left: 10px;
            margin-top: 10px;
            font-size: 16px;
            height: 35px;
            width: 65px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .user-pannel {
    position: relative;
    .Tabs {
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      .membership-status {
        .box {
          width: 100%;
          height: 300px;
          flex-direction: column;
          align-items: center;
          position: relative;
          .circuler-box {
            padding: 0;
            margin: 0;
            margin-top: 30px;
          }
          .remining-days {
            top: 60px;
            left: 50%;
            transform: translate(-50%, 0%);
          }
          .details-box {
            text-align: center;
            padding: 0;
            margin: 0;
            margin-top: 30px;
          }
        }
      }
    }
    .menues {
      width: 100%;
      display: flex;
      justify-content: space-around;
      min-height: 60px;
      padding: 0;
      align-items: center;
      position: fixed;
      top: 100%;
      left: 0;
      transform: translate(0%, -100%);

      a {
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
      }
      .single-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        height: 100%;
        width: 100%;
        .item-text {
          display: none;
        }
      }
    }
  }
  .input-1 {
    width: 100% !important;
  }
  .w-75 {
    width: 100% !important;
  }
}

input {
  box-sizing: border-box;
}
