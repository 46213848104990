.public-profile-view {
  .abx-cc{
    padding-bottom: 20px;
    p{
      font-weight: bolder;
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    .user-certificates {
      display: flex;
      flex-wrap: wrap;
      p{
        font-weight: bolder;
        font-size: 16px;
        
      }
     
    }
  }

  .header {
    height: 240px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    img {
      height: 150px;
      width: 150px;
      border-radius: 150px;
      margin-left: 60px;
    }
    .user-det {
      color: white;
      margin-left: 30px;
      .user-name {
        font-weight: 700;
        font-size: 30px;
        text-transform: capitalize;
        color: #ffffff;
      }
      .user-position {
        font-size: 20px;
      }
    }
  }
  .tow-side {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 40px;
    .header-title {
      border: 2px solid #202020;
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 18px;
      text-transform: capitalize;
      color: #202020;
    }
    .left-side {
      width: 49%;
    }
    .right-side {
      width: 49%;
      .options {
        display: flex;
        align-items: center;
        height: 35px;
        border: 1px solid black;
        width: 280px;
        margin-top: 20px;
        button {
          height: 100%;
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding: 0;
          font-weight: 700;
          font-size: 16px;
          text-transform: capitalize;
          cursor: pointer;
          color: #202020;

          svg {
            height: 18px;
            width: 18px;
            path {
              fill: #202020;
            }
            margin-right: 10px;
          }
        }
        .active-option {
          color: white;
          background-color: black;
          svg path {
            fill: white;
          }
        }
      }
    }
  }
  .semi-title {
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 6px;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    height: 35px;
    padding-left: 10px;
    border: 1px solid gray;
    border-radius: 5px;
  }
  .duel-item {
    display: flex;
    justify-content: space-between;
    .duel-single-item {
      width: 48%;
    }
  }
  .links {
    a {
      margin-right: 10px;
      svg {
        height: 40px;
        width: 40px;
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .public-profile-view {
    .header {
      padding: 0 20px;
      box-sizing: border-box;
      width: 100%;
      padding-top: 20px;
      img {
        height: 100px;
        width: 100px;
        margin-top: 10px;
        margin-left: 0;
      }
      .user-det {
        .user-name {
          font-size: 18px;
        }
      }
    }
    .tow-side {
      flex-direction: column;
      .left-side,
      .right-side {
        width: 100%;
      }
      .right-side {
        margin-top: 40px;
      }
    }
  }
}
