.Homepage {
  .Landing-page {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    position: absolute;
    max-height: 800px;
    top: 0;
    left: 0;
    .right-image {
      position: absolute;
      right: 0;
      top: 0;
      min-height: 100%;
      width: 40%;
    }
    .roh-image {
      position: absolute;
      right: 100px;
      height: 42vh;
      max-height: 350px;
      top: 44%;
      width: 550px;
    }
    .roh-text {
      position: absolute;
      left: 100px;
      height: 42vh;
      top: 44%;
      width: 35%;

      h1 {
        margin-top: 8px;
        font-weight: 800;
        font-size: 30px;
        text-transform: capitalize;
        color: #202020;
      }
      p {
        font-size: 16px;
        letter-spacing: 0.035em;
        text-transform: capitalize;
        color: #404040;
        margin-top: 20px;
        line-height: 1.8;
      }
      button {
        height: 40px;
        width: 120px;
        border: 2px solid #202020;
        margin-top: 20px;
        font-weight: 700;
        font-size: 16px;
        text-transform: capitalize;
        color: #202020;
        cursor: pointer;
      }
    }
  }
  .hiden-item-xxy {
    height: 100vh;
    max-height: 800px;
    width: 100%;
  }

  .about-us {
    padding: 40px 100px;
    display: flex;
    justify-content: space-between;
    .video {
      height: 280px;
      width: 480px;
      position: relative;
      overflow: hidden;
      video {
        height: 100%;
        width: 100%;
      }
      .hiden-item-iix {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
      }
    }
    .about-us-text {
      width: calc(100% - 480px);
      padding: 0 40px;
      padding-left: 100px;
      box-sizing: border-box;
      max-height: 280px;
      position: relative;
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-top: 20px;
        letter-spacing: 0.035em;
        text-transform: capitalize;
        color: #404040;
      }

      button {
        height: 40px;
        width: 120px;
        border: 2px solid #202020;
        margin-top: 20px;
        font-weight: 700;
        font-size: 16px;
        text-transform: capitalize;
        color: #202020;
        cursor: pointer;
        position: absolute;
        bottom: 0;
      }
    }
  }
  .title {
    font-weight: bold;
    font-size: 40px;
    color: #202020;
  }
  .team-section {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
    min-height: 100vh;
    background-color: #202020;
    padding: 0 100px;
    box-sizing: border-box;
    @media screen and (max-width : 1040px) {
      padding: 0 20px;
    }
    .butors {
      display: flex;
      margin-top: 40px;
      width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;
      .contributor {
        width: 233px;
        height: 300px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #202020;
        border-radius: 10px;
        box-shadow: 0.6em 0.6em 1.2em rgb(0 0 0 / 32%),
          -0.5em -0.5em 1em #1d1d1d;
        margin-bottom: 40px;
        @media screen and (max-width : 1040px) {
          width: 48%;
        }
        @media screen and (max-width : 768px) {
          width: 100%;
        }
        .profile-pic {
          height: 100px;
          width: 100px;
          border-radius: 100px;
          overflow: hidden;
          img {
            max-width: 100%;
            max-width: 100%;
            display: unset;
            object-fit: cover;
          }
        }
        .name {
          font-size: 16px;
          font-weight: bolder;
          color: #d2beff;
          margin-top: 20px;
          text-decoration: none;
        }
        .tech {
          margin-top: 3px;
          font-size: 12px;
          color: #767c91;
        }
        .social {
          display: flex;
          margin-top: 40px;
          a {
            text-decoration: none;
            color: #6c758f;
            font-size: 20px;
            margin: 6px;
            transition: 0.4s all;
            cursor: pointer;
            &:hover {
              color: #0196e3;
            }
          }
        }
      }
    }
    .title {
      color: white;
      margin-top: 60px;
      margin-bottom: 20px;
    }
    .back-image {
      width: 100%;
      overflow: hidden;
      position: absolute;
      max-height: 1600px;
      z-index: -1;
      top: 0;
      left: 0;
      img {
        width: 100%;
      }
    }

    .team {
      margin-top: 40px;
      .outer-box-image {
        height: 160px;
        overflow: hidden;
        border-radius: 8px;
        width: 140px;
        background-color: #2fb166;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          // min-width: 100%;
          // min-height: 100%;
          // max-width: 100%;
          max-height: 100%;
        }
      }
      .name {
        color: white;
        font-weight: bolder;
        font-size: 18px;
        margin-top: 6px;
        max-width: 150px;
        cursor: pointer;
      }
      .position {
        color: white;
        font-size: 14px;
        max-width: 150px;
      }
    }
    .js {
      display: flex;
      width: 100%;
      padding: 0 100px;
      justify-content: center;
      box-sizing: border-box;
      .js-1 {
        margin: 0 30px;
      }
    }
    .em {
      display: flex;
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0 100px;
      flex-wrap: wrap;
      box-sizing: border-box;
      row-gap: 80px;
      margin-top: 80px;
      justify-content: center;
      .js-1 {
        flex: 20%;
        max-width: 20%;
        .team-xy {
          width: 150px;
          margin: auto;
        }
      }
    }
  }

  .achivements {
    width: 100%;
    padding: 0 100px;
    margin: 40px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    margin-top: 100px;

    .title {
      margin-bottom: 40px;
    }
    .carousel {
      max-height: 400px;
      width: 90%;
      position: relative;
      min-height: 300px;

      .a {
        position: absolute;
        height: 200px;
        background-color: gray;
        width: 400px;
        top: 50%;
        transition: 0.2s all;
        overflow: hidden;
        img {
          min-height: 100%;
          min-width: 100%;
        }
      }
      .a1 {
        height: 150px;
        width: 300px;
        background-color: rgb(143, 143, 143);
        z-index: 98;
        transform: translate(0%, -50%);
        left: 0;
      }
      &.mobile {
        display: none;
      }
      .a2 {
        height: 200px;
        width: 350px;
        background-color: rgb(69, 69, 69);
        left: 25%;
        transform: translate(-25%, -50%);
        z-index: 99;
      }
      .a3 {
        left: 50%;
        transform: translate(-50%, -50%);
        height: 250px;
        width: 400px;
        background-color: black;
        z-index: 100;
      }
      .a4 {
        height: 200px;
        width: 350px;
        background-color: rgb(86, 86, 86);
        left: 75%;
        transform: translate(-75%, -50%);
        z-index: 99;
      }
      .a5 {
        height: 150px;
        width: 300px;
        background-color: rgb(143, 143, 143);
        left: 100%;
        transform: translate(-100%, -50%);
        z-index: 98;
      }
      .a.aleft {
        transform: translate(-100%, -50%);
        transition: 0.2s all;
      }
      .a.aright {
        transition: 0.2s all;
        height: 250px;
        width: 400px;
      }
      .a.aaright {
        transition: 0.2s all;
        height: 200px;
        width: 350px;
      }
      .a.aaleft {
        transform: translate(-100%, -50%);
        transition: 0.2s all;
      }
      .a6 {
        height: 150px;
        width: 300px;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 98;
        transition: 0.2s all;
      }

      .a.bleft {
        transform: translate(-5%, -50%);
        transition: 0.2s all;
      }
      .a.bright {
        transition: 0.2s all;
        height: 250px;
        width: 400px;
      }
      .a.bbleft {
        transform: translate(0%, -50%);
        transition: 0.2s all;
      }
      .a.bbright {
        height: 200px;
        width: 350px;
      }
    }
    .year {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      height: 50px;
      position: relative;
      overflow: hidden;
      p {
        font-weight: 800;
        font-size: 40px;
        text-transform: capitalize;
        color: #231f20;
        position: absolute;
        left: 50%;
        top: 0;
        height: 100%;
        transform: translate(-50%, 0%);
      }
    }
    .navigation {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 140px;
      box-sizing: border-box;
      align-items: center;
      .left,
      .right {
        cursor: pointer;
      }
      svg {
        height: 50px;
        width: 50px;
      }
      .caption {
        width: 70%;
        height: 60px;
        background-color: #202020;
        background: #222222;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        .text {
          color: white;
          font-size: 24px;
          text-transform: capitalize;
          height: 100%;
          font-weight: bolder;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.2s all;
          overflow: hidden;
          position: absolute;
          left: 0;
        }
        .text.full-left {
          left: -100%;
          transition: 0.2s all;
        }
        .text.full-right {
          left: 100%;
          transition: 0.2s all;
        }
      }
    }
  }
  .Events {
    background-color: #f5f6f8;
    width: 100%;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 120px;
    padding-top: 100px;
    box-sizing: border-box;
    padding-bottom: 100px;
    padding-top: 40px;

    .all-events {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .event-item {
        flex: 300px;
        height: 220px;
        max-width: 30%;
        min-width: 300px;
        margin-bottom: 1%;
        margin-right: 1%;
        background-color: white;
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px,
          rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px,
          rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
        .title-xy {
          display: flex;
          align-items: center;
          .Date {
            font-size: 60px;
            color: #647182;
            font-weight: 900;
            margin-left: 20px;
          }
          .mon-time {
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            font-weight: bolder;
            color: #647182;
          }
        }
        .body {
          font-weight: bolder;
          font-size: 20px;
          margin-left: 20px;
          color: #202020;
          margin-right: 20px;
        }
        .venue {
          position: absolute;
          bottom: 0;
          padding-left: 20px;
          width: 100%;
          height: 60px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-top: 1px solid #b2b2b2;
          font-weight: bolder;
          color: #647182;
          p {
            font-weight: 500;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.landing-page-image-upload {
  .MuiPaper-root {
    min-width: 60%;
    max-height: 80vh;
  }
  .image-item {
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
    position: relative;
    img {
      width: 100%;
    }
    button {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: rgb(255, 188, 188);
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      border-radius: 6px;
      color: red;
    }
  }
}
@media screen and (max-width: 1040px) {
  .Homepage {
    .hiden-item-xxy {
      display: none;
    }
    .Landing-page {
      min-height: 1000px;
      position: relative;
      .right-image {
        width: 100%;
        img {
          transform: scale(1.5);
        }
      }
      .roh-image {
        width: 90%;
        left: 50%;
        height: 450px;
        right: 30px;
        top: 80%;
        transform: translate(-50%, -80%);
        button {
          background-color: black;
        }
      }

      .edit-button {
        width: 100px;
      }

      .roh-text {
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
        top: 200px;
        h1 {
          font-size: 26px;
          color: white;
        }
        p {
          color: rgb(213, 213, 213);
          margin-top: 30px;
        }
        button {
          border: 1px solid white;
          color: white;
          margin-top: 30px;
        }
      }
    }
    .about-us {
      flex-direction: column;
      padding: 0px 20px;
      margin-top: 100px;
      box-sizing: border-box;
      .video {
        width: 100%;
        height: 400px;
      }
      .hiden-item-iix {
        display: none;
      }
      .about-us-text {
        width: 100%;
        padding: 0;
        margin-top: 40px;
        button {
          position: unset;
        }
      }
    }
    .achivements {
      padding: 40px 10px;
      .carousel {
        width: 100%;
        overflow: hidden;
        display: none;
        .a.aleft {
          left: 50%;
          transform: translate(-150%, -50%);
        }
        &.mobile {
          min-height: 400px;
          display: block;
          height: 0px;
        }
      }
      .year {
        display: none;
      }
      .navigation {
        display: none;
      }
    }
    .team-section {
      overflow: hidden;
      background-color: #202020;
      .team {
        flex-wrap: wrap;
        padding: 0;
        grid-gap: 10px;
        .js-1 {
          margin: 0;
          padding: 0;
          margin-top: 20px;
          max-width: unset;
          flex: unset;
          .name {
            font-size: 16px;
          }
          .position {
            font-size: 12px;
          }
        }
      }
    }
    .Events {
      padding: 40px 20px;
      height: unset;
      min-height: unset;
      .all-events {
        justify-content: space-between;
        .event-item {
          width: 100%;
          max-width: 48%;
        }
      }
    }
  }
}

.edit-button {
  background-color: rgba(0, 0, 0, 0.232);
  color: white;
  padding: 5px 10px;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .Homepage {
    .Landing-page {
      min-height: 100vh;
      .roh-text {
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
        top: 120px;
      }
      .roh-image {
        width: 90%;
        left: 50%;
        height: 200px;
        right: 30px;
        top: 90%;
        transform: translate(-50%, -90%);
      }
    }
    .about-us {
      .video {
        height: 200px;
      }
    }
    .achivements {
      .carousel {
        &.mobile {
          height: 200px;
          max-height: 200px;
          min-height: 200px;
        }
      }
    }
    .Events {
      .title {
        font-size: 26px;
        width: 100%;
        text-align: center;
      }
      max-height: unset;
      min-height: unset;
      height: unset;
      .all-events {
        display: unset;
        .event-item {
          max-width: 100%;
          margin-bottom: 20px;
          height: 200px;
        }
      }
    }
  }
}
