.Footer {
  padding: 0 100px;
  height: 320px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .copy-write-text {
    font-size: 14px;
    color: white;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .border-line {
    width: 100%;
    height: 1px;
    background-color: white;
    margin-top: 40px;
  }
  .foter-items {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .find-us {
      .find-us-buttons {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 5px;
      }
      .item {
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
    .logo {
      img {
        height: 100px;
        width: 100px;
      }
    }
    .title {
      color: white;
      font-size: 20px;
    }
    .about-us-text {
      width: 40%;
      p {
        color: white;
        font-size: 14px;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .Footer {
    height: unset;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .foter-items {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 40px;
      .about-us-text {
        width: 90%;
        text-align: center;
        margin-top: 40px;
      }

      .find-us {
        margin-top: 40px;
        .title {
          margin-bottom: 10px;
        }
      }
    }

    .copy-write-text {
      width: 90%;
      padding-bottom: 20px;
    }
  }
}
