.contest-rank {
  border: 1px solid black;
  height: 80px;
  margin-top: 25px;
  border-radius: 6px;
  width: 100%;
  @media screen and (max-width: 1040px) {
    height: 100px;
  }
  .tow-sided {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    align-items: center;
    padding: 0 20px;
    .left-side {
      width: 75%;
      h1 {
        font-size: 18px;
        color: #202020;
        @media screen and (max-width: 1040px) {
          font-size: 15px;
        }
      }
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 25px;
        text-transform: capitalize;
        color: #202020;
        margin-top: 4px;
        @media screen and (max-width: 1040px) {
          font-size: 12px;
        }
      }
    }
    .right-sided {
      width: 25%;
      display: flex;
      align-items: center;
      .rank-svg {
        svg {
          height: 30px;
          @media screen and (max-width: 1040px) {
            height: 25px;
          }
        }
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .rank {
        font-weight: bold;
        font-size: 25px;
        @media screen and (max-width: 1040px) {
          font-size: 20px;
        }
      }
    }
  }
}
