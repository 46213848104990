.certificates {
  padding: 0 100px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
    padding-top: 60px;
  }
  .add-buttons {
    width: 100%;
    margin-top: 20px;
    display: flex;
    .buttons {
      font-size: 10px;
      color: white;
      background-color: #202020;
      margin-right: 10px;
      @media screen and (max-width: 768px) {
        width: 48%;
        font-size: 10px;
      }
    }
  }
  .search-box {
    display: flex;
    height: 35px;
    width: 50%;
    margin: 20px auto;
    align-items: center;
    font-size: 30px;
    padding-left: 6px;
    overflow: hidden;
    border: 1px solid rgb(52, 52, 52);
    input {
      width: calc(100% - 30px);
      border: none;
      outline: none;
      padding-left: 6px;
      box-sizing: border-box;
      height: 100%;
    }
  }
  .certi-det {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      width: 100%;
      font-size: 10px;
      flex-direction: column;
      justify-content: flex-start;
    }
    img {
      width: 60%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    .left-texts {
      width: 40%;
      padding: 20px 20px;
      box-sizing: border-box;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .button-group {
        display: flex;
        justify-content: space-between;
        a {
          text-decoration: none;
          width: 48%;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          .button {
            background-color: #1ba94c;
            color: white;
            width: 100%;
            border-radius: 2px;
            font-size: 10px;
            svg {
              margin-left: 6px;
            }
          }
        }
      }
      input {
        width: 100%;
        height: 35px;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }
  }
}
