.contest-details-xy {
  .Contest-details {
    padding: 0 100px;
    box-sizing: border-box;
    .contest-banner {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .enroll-users{
    margin-left: 100px;
    margin-top: 40px;
    @media screen and (max-width : 768px) {
      margin-left: 20px;
    }
    h1{
      font-size: 18px;
    }
    ol{
      list-style-position: inside;
      margin-top: 10px;
      li{
        margin-top: 5px;
      }
    }
  }
  .states {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-right: 5px;
    padding-top: 5px;
    .state {
      padding: 3px 6px;
      color: white;
      float: right;
      max-width: 400px;
      border: 1px solid #ef8328;
      border-radius: 10px;
      color: #ef8328;
      font-size: 12px;

      &.upcoming {
        border: 1px solid #803e96;
        color: #803e96;
      }
      &.finished {
        border: 1px solid #2db546;
        color: #2db546;
      }
    }
  }
  .details {
    width: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    display: flex;
    padding-bottom: 40px;
    .details-side {
      width: 50%;
    }
    .contdown-zone {
      width: 50%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h1 {
      font-weight: bold;
      font-size: 30px;
      text-transform: capitalize;
    }
    .date-sec {
      display: flex;
      margin-top: 10px;
      p {
        margin-right: 10px;
        margin-top: 0;
      }
    }
    p {
      margin-top: 10px;
      font-weight: bolder;
      color: #363636;
    }
  }
  .team-section {
    width: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
  }
  .registration-now {
    input {
      margin-top: 10px;
      height: 30px;
      padding-left: 10px;
      box-sizing: border-box;
      width: 100%;
      max-width: 400px;
    }
    .members {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
      h2 {
        text-transform: capitalize;
      }
    }
    button {
      width: 300px;
      height: 40px;
      background-color: black;
      margin-top: 20px;
      color: white;
    }
  }
  .pending-request {
    width: 50%;
    margin-bottom: 20px;
    .request-item {
      margin-top: 20px;
      button {
        width: 80px;
        height: 25px;
        background-color: red;
        color: white;
        font-weight: bolder;
        font-size: 16px;
        margin-right: 10px;
        margin-top: 10px;
        &.ac {
          background-color: green;
        }
      }
    }
    .team-members {
      display: flex;
      .member {
        color: green;
        margin-right: 10px;
        &.pending {
          color: rgb(202, 74, 0);
        }
      }
    }
  }
  .details-section {
    margin-top: 40px;
  }
  .enroll-button{
    margin-left: 100px;
    width: 160px;
    height: 35px;
    background-color: black;
    color: white;
    font-size: 16px;
    font-weight: bolder;
    margin-top: 40px;
    @media screen and (max-width : 768px) {
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 1040px) {
  .contest-details-xy {
    padding-top: 60px;
    .Contest-details {
      padding: 0 20px;
    }
    .details {
      padding: 0 20px;
      flex-direction: column;
      flex-direction: column-reverse;
      .details-side {
        width: 100%;
        h1 {
          font-size: 16px;
          margin-top: 20px;
        }
        p {
          font-size: 14px;
        }
      }
      .contdown-zone {
        height: unset;
        width: 100%;
        .countdown {
          font-size: 12px;
        }
      }
    }
    .registration-now {
      button {
        width: 100%;
      }
    }
    .team-section {
      flex-direction: column-reverse;
      padding: 0 20px;
      .registration-now {
        width: 100%;
      }

      .pending-request {
        margin-bottom: 50px;
        width: 100%;
        h1 {
          font-size: 18px;
        }
        .request-item {
          font-size: 14px;
        }
      }
    }
  }
}
