.contributors {
  padding: 0 100px;
  box-sizing: border-box;
  padding-top: 40px;
  background-color: #f2f3f7;
  min-height: 100vh;

  @media screen and (max-width : 768px) {
    padding: 0 20px;
    padding-top: 100px;
    padding-bottom: 40px;
  }
  h1{
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
  }
  .c-text {
    font-size: 16px;
    color: rgb(63, 63, 63);
    span {
      color: #202022;
      font-size: 20px;
      font-weight: bolder;
    }
  }
  img {
    display: none;
  }
  .butors {
    display: flex;
    margin-top: 40px;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    .contributor {
      width: 333px;
      height: 416px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #f2f3f7;
      border-radius: 20px;
      box-shadow: 0.6em 0.6em 1.2em #d2dce9, -0.5em -0.5em 1em #ffffff;
      margin-bottom: 40px;
      .profile-pic {
        height: 180px;
        width: 180px;
        border-radius: 100px;
        overflow: hidden;
        img {
          max-width: 100%;
          max-width: 100%;
          display: unset;
          object-fit: cover;
        }
      }
      .name {
        font-size: 20px;
        font-weight: bolder;
        letter-spacing: 1px;
        color: #36187d;
        margin-top: 20px;
        text-decoration: none;
      }
      .tech {
        margin-top: 3px;
        font-size: 14px;
        color: #6c758f;
      }
      .social {
        display: flex;
        margin-top: 40px;
        a {
          text-decoration: none;
          color: #6c758f;
          font-size: 30px;
          margin: 10px;
          transition: 0.4s all;
          cursor: pointer;
          &:hover {
            color: #0196e3;
          }
        }
      }
    }
  }
}
