.rich-text-view{
    padding: 0 100px;
    box-sizing: border-box;
    @media screen and (max-width : 1040px) {
        padding: 0 20px;
    }
    ol{
        list-style-position: inside;
    }
    ul{
        list-style-position: inside;
    }
}