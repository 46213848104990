.Admin-pannel {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  position: fixed;
  max-width: 1440px;
  .Tabs {
    width: 65%;
    padding-left: 50px;
    padding-top: 30px;
    padding-right: 100px;
    max-height: 100vh;
    overflow-y: scroll;
    margin-left: 35%;
    padding-bottom: 50px;
    box-sizing: border-box;
    .upload-box {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 200px;
      border-radius: 12px;
      border: 1px dashed #000000;
    }
    p {
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
  .single-rank {
    display: flex;
    padding: 20px;
    width: 100%;
    border: 1px solid #000000;
    border-radius: 6px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .picture {
      height: 80px;
      width: 80px;
      margin-right: 20px;
      img {
        height: 100%;
        border-radius: 3px;
        width: 100%;
      }
    }
    .left-side {
      display: flex;
      flex-direction: column;
      .team-name {
        font-weight: 600;
        font-size: 24px;
        color: #202020;
      }
    }
    .right-side {
      button {
        margin-left: 20px;
        background: rgba(73, 186, 84, 0.05);
        border: 1px solid #49ba54;
        border-radius: 12px;
        width: 60px;
        height: 40px;
        svg {
          height: 20px;
          width: 20px;
        }
      }
      button:nth-child(1) {
        background: rgba(216, 51, 51, 0.05);
        border: 1px solid #d83333;
        border-radius: 12px;
      }
    }
    .right-side-x {
      .hexbutton {
        margin-left: 20px;
        background: rgba(73, 186, 84, 0.05);
        border: 1px solid #49ba54;
        border-radius: 12px;
        width: 60px;
        height: 40px;
        svg {
          height: 20px;
          width: 20px;
        }
      }
      .hexbutton:nth-child(1) {
        background: rgba(216, 51, 51, 0.05);
        border: 1px solid #d83333;
        border-radius: 12px;
      }
    }
  }
  .input-1 {
    width: 100%;
    height: 30px;
    border: 1px solid #000000;
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  .single-line-inputs {
    display: flex;
    justify-content: space-between;
    .input-form-item {
      width: 45%;
      input {
        height: 30px;
        width: 100%;
        border: 1px solid #000000;
        border-radius: 5px;
        margin-bottom: 10px;
        padding-left: 10px;
      }
    }
  }

  .post-button {
    cursor: pointer;
    background: #202020;
    border: 3px solid #202020;
    margin-bottom: 100px;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-transform: capitalize;
    color: #ffffff;
    width: 150px;
    height: 40px;
    left: 1384px;
    top: 1389px;
    margin-top: 20px;
    float: right;
  }
  .update-standings {
    font-size: 16px !important;
    width: 200px;
  }
  .form {
    display: flex;
    flex-direction: column;
  }
  .update-button {
    float: unset;
    margin-bottom: 30px;
  }
  .uploaded-image-preview {
    margin-top: 20px;
    width: 100%;
    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }
  .input-2 {
    width: 30%;
    height: 30px;
    border: 1px solid #000000;
    border-radius: 5px;
    margin-bottom: 15px;
    padding-left: 10px;
    box-sizing: border-box;
  }
  .title {
    font-weight: 800;
    font-size: 25px;
    line-height: 50px;
    text-transform: capitalize;
  }
  .admin-nav {
    width: 35%;
    min-height: calc(100vh - 60px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 40px;
    position: absolute;
    box-sizing: border-box;
    background-color: #202020;
    a {
      text-decoration: none !important;
    }
    .single-item {
      padding-left: 10%;
      margin-top: 10px;
      font-weight: 800;
      font-size: 20px;
      display: flex;
      color: white;
      align-items: center;
      // text-transform: capitalize;
      height: 60px;
      svg {
        height: 24px;
        width: 24px;
        path {
          fill: rgb(242, 242, 242);
        }
      }
      .item-text {
        margin-left: 10px;
      }
      &.active {
        color: #222222;
        background-color: white;
        svg {
          path {
            fill: rgb(0, 0, 0);
          }
        }
      }
    }
  }

  .all-users {
    margin-top: 70px;
  }
  .approve-new-user {
    .header-xy {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 200px;
        overflow: hidden;
        svg {
          height: 20px;
        }
        // border: 1;
        border: 2px solid #202020;
        input {
          height: 100%;
          outline: none;
          border: none;
          font-size: 15px;
          width: 75%;
        }
      }
    }
  }
  .open-details {
    width: 80%;
    .input-1 {
      width: 100%;
    }
    .single-line {
      display: flex;
      justify-content: space-between;
      .left-side,
      .right-side {
        width: 45%;
      }
      .drop-down {
        fieldset {
          display: none;
        }
      }
    }
  }
  .opened-box {
    background-color: black;
    .left-side {
      .team-name,
      .team-position {
        color: white;
      }
    }
  }

  .update-gallery {
    width: 100%;
    .header-sec {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        float: unset;
        margin: 0;
      }
    }
  }
  .ritch-text-pro {
    margin-top: 20px;
    padding-bottom: 20px;
    .ql-editor {
      height: 150px;
    }
  }
  .goto-links-button {
    height: 25px !important;
    width: 25px !important;
    border-radius: 3px !important;
    img {
      height: 15px;
      width: 15px;
    }
  }
}

@media screen and (max-width: 1040px) {
  .Admin-pannel {
    .admin-nav {
      z-index: 1000;
      .single-item {
        svg {
          height: 16px;
        }
        .item-text {
          font-size: 16px;
          margin-left: 5px;
        }
      }
    }

    .Tabs {
      padding: 20px;
      width: calc(65% - 20px);
      padding-top: 40px;
      z-index: 0;
    }

    .links {
      .two-sided {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100px;
        .left-side {
          svg {
            height: 40px;
            margin-left: 0;
          }
          .name-text {
            font-size: 16px;
            margin-left: 0px;
          }
        }
        .right-side {
          .input-1 {
            border-radius: 5px;
            margin-left: 10px;
            margin-top: 10px;
            font-size: 16px;
            height: 35px;
            &::placeholder {
              font-size: 10px;
            }
          }
          .link-details {
            margin-left: 10px;
            margin-top: 10px;
          }
          .post-button {
            border-radius: 5px;
            margin-left: 10px;
            margin-top: 10px;
            font-size: 16px;
            height: 35px;
            width: 65px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .Admin-pannel {
    position: relative;
    .Tabs {
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      margin-left: 0px;
      margin-top: 40px;
      .input-2 {
        width: 100% !important;
        box-sizing: border-box;
      }
      .approve-new-user {
        .header-xy {
          flex-direction: column;
          align-items: flex-start;
        }
        width: 100%;
        .all-users {
          width: 100%;
          .single-rank {
            max-width: 100%;
            overflow: hidden;
            width: unset;
            .right-side-x {
              display: flex;
              .hexbutton {
                height: 30px;
                width: 30px;
                border-radius: 2px;
                margin-left: 5px;
                svg {
                  height: 20px;
                }
              }
            }
            .left-side {
              .team-name {
                font-size: 15px;
              }
              .team-position {
                font-size: 14px;
              }
            }
          }
        }
      }
      .single-line-inputs {
        display: flex;
        flex-direction: column;
        width: 100%;
        .input-form-item {
          width: 100%;
        }
      }
      .upload-box {
        z-index: 0;
        .upload-icon svg {
          height: 50px;
        }
        p {
          font-size: 14px;
          padding: 20px;
          text-align: center;
        }
      }
      .membership-status {
        .box {
          width: 100%;
          height: 300px;
          flex-direction: column;
          align-items: center;
          position: relative;
          .circuler-box {
            padding: 0;
            margin: 0;
            margin-top: 30px;
          }
          .remining-days {
            top: 60px;
            left: 50%;
            transform: translate(-50%, 0%);
          }
          .details-box {
            text-align: center;
            padding: 0;
            margin: 0;
            margin-top: 30px;
          }
        }
      }
    }
    .admin-nav {
      width: 100%;
      display: flex;
      justify-content: space-around;
      min-height: 60px;
      padding: 0;
      align-items: center;
      position: fixed;
      top: 100%;
      left: 0;
      transform: translate(0%, -100%);
      z-index: 1000;
      a {
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
      }
      .single-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        height: 100%;
        width: 100%;
        .item-text {
          display: none;
        }
      }
    }
  }
 
}
.Achievements-admin-pannel {
  .upload-new {
    .post-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0 !important;
      margin-top: 20px;


    }
  }

  .all-achievements{
    margin-top: 100px;
    .achivement-item{
      height: 250px;
      display: flex;
      @media screen and (max-width : 1040px) {
        flex-direction: column;
        height: 400px;
      }
      img{
        height: 200px;
      }
      h1{
        font-size: 28px;
      }
      p{
        font-size: 20px;
      }
      .details-sec{
        margin-left: 20px;
        height: 100%;
      }
      button{
        background-color: rgb(221, 67, 67);
        height: 40px;
        width: 100px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}
input {
  box-sizing: border-box;
}
.sc-bcXHqe {
  z-index: 0;
}

.contest-edit{
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  p{
    margin: 20px 0px;
  }
  input{
    width: 100%;
    height: 40px;
    padding-left: 10px;
    box-sizing: border-box;
  }
  img{
    width: 100%;
  }
  button{
    width: 120px;
    height: 40px;
    background-color: #202020;
    color: white;
    margin-top: 20px;
    cursor: pointer;

  }
}