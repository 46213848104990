.Header {
  width: 100%;
  height: 60px;
  position: fixed;
  background-color: white;
  z-index: 100000;
  top: 0;
  max-width: 1440px;
  left: 50%;
  transform: translateX(-50%);
  .logo {
    height: 50px;
    width: 50px;
    margin-left: 100px;
    cursor: pointer;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-side {
    display: flex;
    align-items: center;
    .mobile-name {
      display: none;
    }
    .menu-items {
      display: flex;
      .item {
        margin-left: 50px;
        font-weight: 600;
        font-size: 14px;
        line-height: 25px;
        text-transform: capitalize;
        color: #202020;
        text-decoration: none;
        // font-family: 'pantherdam';
      }
      .show-mobile {
        display: none;
      }
    }
  }
  .profile {
    margin-right: 100px;
    display: flex;
    align-items: center;
    p {
      font-weight: 600;
      font-size: 14px;
      text-transform: capitalize;
      color: #202020;
    }
    img {
      height: 40px;
      width: 40px;
      border-radius: 40px;
    }
  }
  .drop-down {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 10px;
    cursor: pointer;
    button {
      margin-left: 10px;
      svg {
        width: 10px;
      }
    }
  }

  .auth {
    margin-right: 100px;
    cursor: pointer;
    font-weight: 600;
    a {
      text-decoration: none;
      color: #202020;
      cursor: pointer;
    }
  }
}

.drop-down {
  position: relative;
  z-index: 10000;
  .drop-down-items {
  background-color: white;
    position: absolute;
    width: 80%;
    top: calc(100% + 10px);
    left: 120%;
    transform: translateX(-120%);
    border-radius: 5px;
    min-width: 150px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    .dr-item {
      width: 100%;
      padding: 8px 0;
      padding-left: 15px;
      box-sizing: border-box;
      color: #202020;
      cursor: pointer;
      font-weight: 600;
    }
    .dr-item:hover {
      background-color: rgb(232, 255, 232);
      transition: 0.5s all;
    }
  }
}

@media screen and (max-width: 1040px) {
  .Header.open-slider {
    left: 0%;
    transition: 0.5s all;
  }
  .Header {
    height: 100vh;
    width: 80%;
    left: -100%;
    transition: 0.5s all;
    position: fixed;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 1px solid rgb(213, 213, 213);
    transform: translate(0, 0);
    .pcist-text {
      font-size: 12px;
    }
    .left-side {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      .logo {
        margin: 0;
        margin-top: 40px;
        margin-left: 40px;
      }
      .mobile-name {
        display: block;
        margin-left: 40px;
        margin-top: 10px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .menu-items {
        flex-direction: column;
        margin-left: 40px;
        .item {
          margin-left: 0;
          padding-left: 0;
          font-size: 18px;
          margin-top: 20px;
          font-weight: bolder;
        }
        .show-mobile {
          display: block;
        }
      }
    }
    .user-options {
      display: none;
      .profile {
        display: none;
      }
    }
  }
  .menu-open-button {
    z-index: 100000;
    position: fixed;
    top: 30px;
    left: 30px;
    .container {
      display: inline-block;
      cursor: pointer;
    }

    .bar1,
    .bar2,
    .bar3 {
      width: 35px;
      height: 5px;
      background-color: #333;
      margin: 6px 0;
      transition: 0.4s;
    }

    .change .bar1 {
      transform: translate(0, 11px) rotate(-45deg);
    }

    .change .bar2 {
      opacity: 0;
    }

    .change .bar3 {
      transform: translate(0, -11px) rotate(45deg);
    }
  }
}

