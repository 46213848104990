.countdown{
    display: flex;
    font-size: 18px;
    p{
        margin: 0;
        padding: 0;
        margin-top: 0!important;
        margin:0 10px;
        margin-left: 6px;
    }
    span{
        display: none;
    }
}