.certificate-details {
  min-height: 100vh;
  padding: 0 100px;
  box-sizing: border-box;
  background-color: #f3f7f7;
  padding-top: 20px;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
    padding-top: 60px;
  }
  .share-this{
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bolder;
  }
  h1 {
    text-transform: uppercase;
    font-size: 16px;
  }
  .certificate {
    width: 100%;
    margin-top: 20px;
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    img {
      width: 60%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    .details {
      width: 40%;
      padding: 20px;
      box-sizing: border-box;
      padding-top: 0;
      font-weight: 400;
      font-size: 18px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      input {
        width: 100%;
        margin-top: 10px;
        height: 35px;
        padding: 0 6px;
        outline: none;
        box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
        border: none;
      }
      .button-group {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        a {
          text-decoration: none;
          width: 48%;
          .button {
            background-color: #1ba94c;
            color: white;
            width: 100%;
            border-radius: 2px;
            font-size: 10px;
            svg {
              margin-left: 6px;
            }
          }
        }
      }
      a {
        text-decoration: none;
      }
      .button-linkedin {
        width: 150px;
        border-radius: 2px;
        font-size: 15px;
        color: white;
        margin-top: 10px;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        p {
          width: 120px;
          font-size: 10px;
        }
      }
      .details-xy {
        margin-top: 16px;
        width: 100%;
        &.abdd {
          margin-top: 0;
          margin-bottom: 16px;
        }
        box-sizing: border-box;
        h1 {
          font-size: 16px;
        }
        p {
          font-size: 14px;
          margin-top: 6px;
        }
        .abx {
          a {
            display: flex;
            align-items: center;
            p {
              margin-left: 6px;
              margin-top: 0;
            }
          }
        }
        a {
          color: #202020;
        }
        h2 {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }
}
