.contest-details-xy {

  .register-button{
    margin-left: 100px;
    height: 40px;
    width: 180px;
    background-color: #202020;
    color: white;
    font-weight: bolder;
    @media screen and (max-width : 1040px) {
      margin-left: 20px;
      margin-top: 20px;
    }
  }
  .Contest-details {
    padding: 0 100px;
    box-sizing: border-box;
    .contest-banner {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .details {
    width: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    display: flex;
    padding-bottom: 40px;
    .details-side {
      width: 50%;
      .admin-boxes{
        display: flex;
        .all-team-button{
          margin-right: 10px;
         
        }
        
      }
      .all-team-button {
        text-decoration: none;
        button {
          background-color: #202020;
          height: 35px;
          width: 100px;
          color: white;
          font-weight: bolder;
          font-size: 16px;
          margin-top: 20px;

        }
        &.regis{
          button{
            width: 200px;
          }
        }
      }
    }
    .contdown-zone {
      width: 50%;
      height: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h1 {
      font-weight: bold;
      font-size: 40px;
      text-transform: capitalize;
    }
    .date-sec {
      display: flex;
      margin-top: 10px;
      p {
        margin-right: 10px;
        margin-top: 0;
      }
    }
    p {
      margin-top: 10px;
      font-weight: bolder;
      color: #363636;
    }
  }
  .team-section {
    width: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
  }
  .registration-now {
    input {
      margin-top: 10px;
      height: 30px;
      padding-left: 10px;
      box-sizing: border-box;
      width: 100%;
      max-width: 400px;
    }
    .members {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
      h2 {
        text-transform: capitalize;
      }
    }
    button {
      width: 300px;
      height: 40px;
      background-color: black;
      margin-top: 20px;
      color: white;
    }
  }
  .pending-request {
    width: 50%;
    margin-bottom: 20px;
    .request-item {
      margin-top: 20px;
      button {
        width: 80px;
        height: 25px;
        background-color: red;
        color: white;
        font-weight: bolder;
        font-size: 16px;
        margin-right: 10px;
        margin-top: 10px;
        &.ac {
          background-color: green;
        }
      }
    }
    .team-members {
      display: flex;
      .member {
        color: green;
        margin-right: 10px;
        &.pending {
          color: rgb(202, 74, 0);
        }
      }
    }
  }

  .contest-details-standings{
    padding: 0 100px;
    @media screen and (max-width : 1040px) {
      padding: 0 20px;
      margin-top: 20px;
    }
    h1{
      font-size: 20px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
  }
  
}

@media screen and (max-width: 1040px) {
  .contest-details-xy {
    padding-top: 60px;
    .Contest-details {
      padding: 0 20px;
    }
    .details {
      padding: 0 20px;
      flex-direction: column;
      flex-direction: column-reverse;
      .details-side {
        width: 100%;
        h1 {
          font-size: 16px;
          margin-top: 20px;
        }
        p {
          font-size: 14px;
        }
      }

      .contdown-zone {
        height: unset;
        width: 100%;
        .countdown {
          font-size: 12px;
        }
      }
    }
    .registration-now {
      button {
        width: 100%;
      }
    }
    .team-section {
      flex-direction: column-reverse;
      padding: 0 20px;
      .registration-now {
        width: 100%;
      }

      .pending-request {
        margin-bottom: 50px;
        width: 100%;
        h1 {
          font-size: 18px;
        }
        .request-item {
          font-size: 14px;
        }
      }
    }
  }
}
