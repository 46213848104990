.Loader {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.436);
  top: 0;
  left: 0;
}
