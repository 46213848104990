.login-box {
  display: flex;
  justify-content: center;
  height: calc(100vh - 60px);
  width: 100%;
  background-color: rgb(227 237 252);
  position: relative;
  max-height: 1000px;
  align-items: center;
  .login-midle {
    height: 400px;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    img {
      height: 80px;
      width: 80px;
    }

    .Login {
      background-color: white;
      border-radius: 8px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      padding-top: 30px;
      margin-top: 20px;
      padding-bottom: 20px;
      height: 300px;
      width: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-size: 30px;
        color: rgb(31, 55, 75);
        font-weight: 500;
      }
      p {
        font-size: 10px;
        margin-bottom: 40px;
      }
      input {
        width: 350px;
        height: 30px;
        border: 1px solid rgb(212, 212, 212);
        margin-bottom: 20px;
        border-radius: 6px;
        padding-left: 5px;
        &::placeholder{
          color: rgb(194, 194, 194);
        }
      }
      button {
        width: 350px;
        height: 35px;
        margin-bottom: 20px;
        border-radius: 6px;
        padding-left: 5px;
        background-color: rgb(0, 0, 0);
        color: white;
        font-weight: bolder;
        font-size: 20px;

      }
    }

    .extra-tab{
        display: flex;
        margin-top: 40px;
        a{
            color: rgb(34, 34, 34);
            text-decoration: none;
            margin: 0 10px;
        }
    }
  }
}


@media screen and (max-width : 1040px) {
  .login-box .login-midle .Login{
    width: 90%;
  }
  .login-box .login-midle .Login input,button{
    width: 90%;
  }
  .login-box .login-midle .Login button{
    width: 90%;
  }
  .login-box .login-midle .extra-tab{
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    span{
      display: none;
    }
  }
}

.login-footer{
  margin-top: 0;
}