.cntest {
  width: 100%;
  padding: 100px;
  padding-top: 0;
  box-sizing: border-box;
  margin-top: 40px;
  min-height: 100vh;
  .empty-div {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
  }
  .all-contests {
    margin-top: 10px;
    .single-contest {
      display: flex;
      align-items: center;
      overflow: hidden;
      height: 120px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      position: relative;
      margin-top: 20px;
      .date {
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
        width: 70px;
        height: 100%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
        .dat {
          font-weight: 800;
          font-size: 28px;
          color: #ffffff;
        }
        .mon {
          font-weight: 600;
          font-size: 20px;
          color: #ffffff;
        }
      }
      .banner {
        height: 90%;
        margin-left: 10px;
        img {
          height: 100%;
          border-radius: 5px;
        }
      }
      .event-details {
        height: 60%;
        margin-left: 20px;
        .name {
          font-weight: 800;
          font-size: 26px;
          color: #202020;
          text-transform: capitalize;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .times {
          display: flex;
          align-items: center;
          svg {
            height: 16px;
            width: 16px;
            margin-right: 8px;
          }
          .time {
            margin-right: 20px;
          }
          font-weight: bolder;
        }
      }
      .see-details {
        height: 40px;
        width: 120px;
        background-color: black;
        color: white;
        background: #202020;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        font-size: 14px;
        line-height: 33px;
        text-transform: capitalize;
        color: #ffffff;
        position: absolute;
        right: 40px;
        &.delete {
          background-color: rgb(215, 72, 72);
          right: 170px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.menu {
          right: 0;
          top: 0;
          width: 30px;
          font-size: 20px;
          display: flex;
          background-color: transparent;
          color: black;
          align-items: center;
          justify-content: center;
          box-shadow: none;
        }
      }
    }
  }
  .edit-delete {
    width: 100px;
    height: 90px;
    background-color: white;
    border-radius: 5px;
    right: 10px;
    position: absolute;
    top: 25px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .edit,
    .delete {
      width: 100%;
      padding-left: 10px;
      box-sizing: border-box;
      height: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: bolder;
      text-decoration: none;
      color: #202020;
      .finished {
        height: 5px;
        width: 5px;
        border-radius: 5px;
        background-color: lime;
        margin-left: 10px;
      }
      .not-finished {
        height: 5px;
        width: 5px;
        border-radius: 5px;
        background-color: rgb(255, 0, 0);
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .cntest {
    padding: 0 20px;
    margin-top: 70px;
    .all-contests {
      .single-contest {
        flex-direction: column;
        margin-bottom: 40px;
        height: auto;
        padding-bottom: 20px;
        .banner {
          width: 90%;
          margin: auto;
          margin-top: 10px;
          height: unset;
          img {
            width: 100%;
            height: unset;
          }
        }
        .date {
          width: 100%;
          background-color: #202020;
          background-image: none !important;
          display: flex;
          flex-direction: row;
          align-items: center;
          min-height: 50px;
          .dat {
            margin-right: 10px;
          }
        }

        .event-details {
          display: unset;
          flex-direction: column;
          align-items: flex-start;
          width: 90%;
          padding: 0;
          margin: 0;
          margin-top: 10px;
          .name {
            font-size: 16px;
          }
        }
      }
    }
    .all-contests .single-contest .see-details {
      position: relative;
      margin: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin-top: 20px;
    }
    .all-contests .single-contest .see-details.menu {
      top: -14px;
      right: 5px;
      left: unset;
      bottom: unset;
      position: absolute;
      color: white;
      width: unset;
    }
  }
}

.out-display {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.301);
}

.edit-contest {
  position: fixed;
  height: calc(100vh - 100px);
  width: 90%;
  background-color: white;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .title {
    font-size: 26px;
    font-weight: bold;
    height: 60px;
    background-color: #202020;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .button-group {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: black;
    height: 60px;
    display: flex;
    left: 0;
    justify-content: flex-end;
    align-items: center;
    button {
      color: white;
      font-weight: bolder;
      margin-right: 20px;
      font-size: 20px;
    }
  }
}
