.create-certificate-modal {
  .MuiPaper-root {
    max-height: 80vh;
    width: 100%;
    .p {
      margin-top: 20px;
    }
    .tow-sided {
      width: 100%;
      justify-content: space-between;
      display: flex;
      .sx {
        width: 45%;
        input {
          width: 100%;
        }
      }
    }
    .preview {
      width: 100%;
      overflow: hidden;
      margin-top: 20px;
      position: relative;
      border-top: 1px solid black;
      p {
        position: absolute;
        font-size: 10px;
        color: white;
        transform: translate(-50%, -90%);
      }
      img {
        width: 100%;
      }
    }
  }
}
