.upload-box {
    cursor:pointer;
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;
    border-radius: 12px;
    border: 1px dashed #000000;
  }