@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
.About-us {
  padding-bottom: 200px;
  .about-us-header {
    height: 150px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-items: center;
    .title {
      color: white;
      font-size: 60px;
      font-weight: bold;
      display: flex;
      margin: auto;
      -webkit-text-stroke-width: 4px;
      -webkit-text-stroke-color: rgba(255, 255, 255, 0.7);
      .xy {
        color: rgba(255, 255, 255, 0);
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: rgba(255, 255, 255, 0.7);
        margin-left: 8px;
      }
    }
  }

  .body {
    margin-top: 50px;
    padding: 0 100px;
    box-sizing: border-box;
    display: flex;
    p {
      line-height: 25px;
      margin-top: 20px;
      color: #202020;
      a {
        text-decoration: none;
        color: black;
        font-weight: bold;
        text-decoration: underline;
      }

      font-family: "Montserrat", sans-serif;
      font-weight: 400;
    }
    .list {
      margin-left: 40px;
      margin-top: 5px;
      li {
        font-weight: 500;
        margin-top: 10px;
      }
    }
    .video {
      height: 200px;
      width: 350px;
      overflow: hidden;
      position: relative;
      float: left;
      margin-right: 30px;
      margin-bottom: 20px;
      video {
        height: 100%;
        width: 100%;
        border-radius: 6px;
      }
      .hiden-item-iix {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .about-us-text {
      text-align: justify;
      .title {
        width: 100%;
        height: 80px;
        align-items: center;
        position: relative;
        p {
          font-size: 40px;
          position: absolute;
          left: 50%;
          font-family: "Source Code Pro";
          font-style: normal;
          font-weight: 800;
        }
      }
    }
  }
  .title-gallery {
    font-weight: bold;
    font-size: 40px;
    width: 100%;
    text-align: center;
    margin: 40px 0;
    position: relative;
  }
  .gallery {
    padding: 0 100px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .gallery-item {
      height: 200px;
      width: 32%;
      margin-bottom: 5px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      &.add-button {
        border: 1px solid rgb(197, 197, 197);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        color: gray;
      }
      .background-black-xy {
        width: 100%;
        height: 200px;
        background-color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
      .delete-button {
        color: rgb(255, 35, 35);
        position: absolute;
        left: 100%;
        top: 10px;
        transition: 0.2s all;
        z-index: 1000;
      }
      .background-black {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.49);
        position: absolute;
        opacity: 0;
        transition: 0.2s all;
      }
      .gallery-short-text {
        width: 100%;
        height: 40px;
        background-color: #202020;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: white;
        position: absolute;
        overflow: hidden;
        top: 100%;
        transition: 0.2s all;
      }
      &:hover {
        .gallery-short-text {
          top: calc(100% - 40px);
          transition: 0.2s all;
        }
        .background-black {
          opacity: 1;
          transition: 0.2s all;
        }
        .delete-button {
          left: calc(100% - 30px);
          transition: 0.2s all;
        }
      }
    }
  }
}
.add-image-modal-root {
  .MuiPaper-root {
    width: 100%;
    max-height: 70vh;
    .modal-body {
      p {
        font-weight: bolder;
      }
      input {
        width: 100%;
        height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        outline: none;
        border: 1px solid gray;
        margin-top: 10px;
      }
      .preview {
        width: 100%;
        margin-top: 20px;
        img {
          max-width: 100%;
        }
      }
      .upload-box {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 200px;
        border-radius: 12px;
        border: 1px dashed #000000;
      }
    }
  }
}
@media screen and (max-width: 1040px) {
  .About-us {
    padding-bottom: 40px;
    .about-us-header {
      height: 160px;
      .title {
        font-size: 45px;
      }
    }
    .hiden-item-iix {
      display: none;
    }
    .body {
      padding: 40px 20px;
      max-width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      .about-us-text {
        width: 90%;
        text-align: left;
        margin-top: 240px;
        .title {
          width: 100%;
          position: relative;
          height: 80px;
          p {
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            text-align-last: center;
            margin-top: 40px;
          }
        }
        ul.list {
          margin-top: 40px;
        }
      }
    }
    .gallery {
      padding: 20px 20px;
      .gallery-item {
        width: 100% !important;
      }
    }
  }
  #root {
    padding-top: 0 !important;
  }
}
