.registration {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100vh;
  width: 100%;
  background-color: rgb(227 237 252);
  position: relative;
  max-height: 1000px;
  .hero-pic {
    position: absolute;
    height: 380px;
    top: 40%;
    left: 0%;
    transform: translate(0%, -40%);
  }
  .login-midle {
    height: 100%;
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    img {
      height: 80px;
      width: 80px;
    }

    .Login {
      background-color: white;
      padding-top: 100px;
      padding-bottom: 20px;
      height: 100%;
      width: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      padding-left: 100px;
      align-items: flex-start;
      h1 {
        font-size: 30px;
        color: rgb(31, 55, 75);
        font-weight: 500;
      }
      input {
        height: 30px;
        border: 1px solid rgb(200, 200, 200);
        margin-bottom: 20px;
        border-radius: 6px;
        padding-left: 5px;
        &::placeholder {
          color: rgb(194, 194, 194);
        }
      }
      button {
        width: 350px;
        height: 35px;
        margin-bottom: 20px;
        border-radius: 6px;
        padding-left: 5px;
        background-color: rgb(0, 0, 0);
        color: white;
        font-weight: bolder;
        font-size: 20px;
      }
    }

    .extra-tab {
      display: flex;
      margin-top: 40px;
      a {
        color: rgb(34, 34, 34);
        text-decoration: none;
        margin: 0 10px;
      }
    }
  }
  .semi-title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .login-xy {
    width: 60%;
  }
  input {
    width: 100%;
    box-sizing: border-box;
  }
  .single-line-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .input {
      width: 48%;
    }
  }
  .input-1 {
    height: 30px;
    width: 48%;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .login-midle .Login button {
    width: 100%;
    font-size: 16px;
    margin-top: 20px;
  }
}
.registration-done {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 150px;
    width: 150px;
    margin-top: 100px;
  }
  p {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1040px) {
  .registration .hero-pic {
    display: none;
  }
  .registration .login-midle {
    width: 100%;
  }
  .registration .login-midle .Login {
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
  }
  .registration .single-line-item {
    flex-direction: column;
    width: 100%;
    .input {
      width: 100%;
    }
  }
  .registration .login-midle .Login button {
    font-size: 10px;
  }
  .registration .login-midle .extra-tab {
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    span {
      display: none;
    }
  }
  .registration .login-xy{
    width: 80%;
  }
  .registration-done p{
    text-align: center;
    width: 100%;
    padding: 20px;
  }
}

.reg-footer{
  margin-top: 0;
}