.courses {
  padding: 0 100px;
  box-sizing: border-box;
  background-color: #f5f6f8;
  padding-bottom: 100px;
  min-height: 100vh;
  padding-top: 40px;
  @media screen and (max-width : 1040px) {
    padding: 0 20px;
    padding-top: 70px;
  }
  .title {
    display: flex;
    align-items: center;
    button {
      width: 160px;
      height: 35px;
      background-color: #202020;
      color: white;
      text-transform: uppercase;
      font-weight: bolder;
      font-size: 16px;
      margin-left: 20px;
    }
  }

  .all-courses {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    .course {
      flex: 300px 1;
      height: 220px;
      max-width: 30%;
      min-width: 300px;
      margin-bottom: 1%;
      margin-right: 1%;
      background-color: white;
      position: relative;
      @media screen and (max-width : 1040px) {
        min-width: 100%;
        margin-bottom: 20px;;
      }
      box-shadow: rgb(0 0 0 / 7%) 0px 1px 1px, rgb(0 0 0 / 7%) 0px 2px 2px,
        rgb(0 0 0 / 7%) 0px 4px 4px, rgb(0 0 0 / 7%) 0px 8px 8px,
        rgb(0 0 0 / 7%) 0px 16px 16px;
      .states {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
        padding-right: 5px;
        padding-top: 5px;
        .state {
          padding: 3px 6px;
          color: white;
          float: right;
          max-width: 400px;
          border: 1px solid #EF8328;
          border-radius: 10px;
          color: #EF8328;
          font-size: 12px;

          &.upcoming{
            border:1px solid #803E96;
            color: #803E96;
          }
          &.finished{
            border: 1px solid #2DB546;
            color: #2DB546;
          }
        }
        .drop-down{
            margin-left: 10px;
            margin-top: 4px;
            position: relative;
            z-index: 0;
            button{
                font-size: 16px;
            }
            .drop-down-items{
                width: 100px;
                min-width: unset;
                left: unset;
                top: 0;
                left: 100%;
                .item{
                    padding: 5px;
                    cursor: pointer;
                    transition: 0.2s all;
                    &:hover{
                        background-color: rgba(167, 255, 167, 0.294);
                    }
                }
            }
        }
      }
      h1 {
        font-size: 16px;
        margin: 0 20px;
        height: 40px;
      }
      p {
        margin: 0 20px;
        margin-top: 10px;
        font-size: 14px;
        color: #424242;
        font-weight: 500;
      }
      .abx {
        display: flex;
        align-items: center;
        margin-right: 10px;
        font-size: 14px;
        margin-left: 20px;
        margin-top: 10px;
        font-weight: 500;
        div {
          color: rgb(0, 174, 15);
          margin-right: 10px;
          display: flex;
          align-items: center;
        }
      }
      .organize-by {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 60px;
        border-top: 1px solid rgb(173, 173, 173);
        width: 100%;
        button{
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            width: 40px;
            height: 40px;
            font-size: 30px;
        }
        .oo {
          font-weight: 500;
          font-size: 12px;
        }
        p {
          font-size: 14px;
          font-weight: bolder;
          margin: 0;
          margin-top: 5px;
          margin-left: 10px;
          color: #647182;
        }
        .vanue {
          font-weight: bolder;
          color: #647182;
        }
      }
    }
  }
}

.add-new-course-modal {
  width: 100%;
  height: 90vh;
  margin-top: 40px;
  .MuiPaper-root {
    width: 100%;
    .content {
      input {
        width: 100%;
        height: 35px;
        border-radius: 4px;
        border: 1px solid gray;
        outline: none;
        font-size: 15px;
        padding-left: 10px;
        box-sizing: border-box;
        margin-bottom: 20px;
      }
      p {
        color: #202020;
        margin-bottom: 10px;
        font-weight: 500;
      }
      .ql-editor {
        height: 200px;
      }
      .technologies {
        display: flex;
        flex-direction: column;
        width: 100%;
        .tech {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          align-items: center;
          border: 1px solid rgb(214, 214, 214);
          border-radius: 4px;
          padding: 0 10px;
          box-sizing: border-box;
          height: 40px;
          p {
            margin: 0;
            display: flex;
            width: calc(100% - 30px);
          }
        }
        button {
          font-size: 20px;
          color: gray;
          height: 20px;
          width: 20px;
          width: 20px;
          margin-left: 10px;
        }
      }
      img {
        width: 100%;
        border-radius: 4px;
      }
      .tow-sided {
        display: flex;
        width: 100%;
        .axx {
          width: 33%;
          display: flex;
          input {
            width: 20px !important;
            height: 20px !important;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
